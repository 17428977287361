import { loadErrorTypeContainer } from "App/script/loadErrorTypes";
import MaintenanceController from "global/hook/maintenance/interface/MaintenanceController";
import ModalController from "global/hook/modal/interface/ModalController";
import {
  returnUndefinedIfEmptyString,
  returnUndefinedIfOnlyWhitespace,
  trimDataValues,
  trimParamValues,
} from "global/util/utils";
import { ErrorTypesContainer } from "service/data-service/error-typ-controller/container/errorTypeContainer";
import { KeyCloakService } from "./keycloak.service";

interface RecordWithErrorInformation {
  readonly errorTyp: string;
  readonly meldungen: Array<string>;
}

let maintenanceController: MaintenanceController | undefined;
let dialogController: ModalController | undefined;

function getMessageFromErrorType(data: RecordWithErrorInformation) {
  if (data.errorTyp === "NO_ERROR") return;

  if (data.meldungen && data.meldungen.length > 0) {
    const givenErrorMessage = returnUndefinedIfEmptyString(
      data.meldungen.join()
    );

    if (givenErrorMessage) {
      return givenErrorMessage;
    }
  }
  return ErrorTypesContainer.find((errorType) => {
    return errorType.key === data.errorTyp;
  })?.value;
}

const HttpMethods = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PREFLIGHT: "PREFLIGHT",
};

export type EpcomResponse = {
  data?: any;
  headers: { [key: string]: string };
};

export type EpcomRequestConfig = {
  method?: string;
  headers?: { [key: string]: string };
  data?: Object;
  params?: any;
  responseType?: string;
  onEpcomRemoteError?: Function;
};

const authenticatedFetch = (
  abortController: AbortController,
  config: EpcomRequestConfig
): EpcomRequestConfig => {
  let enhancedConfigs = {
    ...config,
    signal: abortController.signal,
  };

  // request interceptor here
  if (KeyCloakService.isLoggedIn()) {
    enhancedConfigs.headers = {
      ...enhancedConfigs.headers,
      Authorization: `Bearer ${KeyCloakService.getToken()}`,
    };
  } else {
    console.warn(
      "Backend call failed - User is not authenticated. Redirect to Keycloak login."
    );
    // abortController.abort();
    KeyCloakService.doLogin();
  }
  return enhancedConfigs;
};

const handleFetchError = (error: any, config: EpcomRequestConfig) => {
  let errorMessage = "Beim Laden der Daten ist ein Fehler aufgetreten!";
  if (!error.response) {
    errorMessage = "Beim Abruf der Daten ist ein Netzwerkfehler aufgetreten!";
  }
  dialogController?.showError(errorMessage, error);
};

export const parseFirewallSupportId = (
  epcomResponse: EpcomResponse,
  contentType: string | null = null
): string => {
  const sessionIdString = "SessionID=";
  var supportId = "";
  if (!contentType?.includes("text/html")) return supportId;
  const responseData = epcomResponse.data.replace(/\s/g, "");
  if (
    responseData.includes("<title>ApplicationFirewallBlockPage</title>") &&
    responseData.includes(sessionIdString)
  ) {
    const startOfSupportId = responseData.indexOf(sessionIdString);
    const endOfSupportId = responseData.indexOf("<br>", startOfSupportId);
    supportId = responseData.substring(
      startOfSupportId + sessionIdString.length,
      endOfSupportId
    );
  }
  return supportId;
};

const handleBackendResponse = async (
  response: Response,
  config: EpcomRequestConfig
): Promise<EpcomResponse> => {
  const epcomResponse = await convertResponse(response, config);
  if (response.status === 401) {
    KeyCloakService.doLogout();
    return {} as EpcomResponse;
  }
  const responseContentType = response.headers.get("content-type");
  const responseContentLength = parseInt(
    response.headers.get("content-length") ?? "0"
  );

  const supportId =
    responseContentLength < 500
      ? parseFirewallSupportId(epcomResponse, responseContentType)
      : "";

  if (supportId) {
    console.error("Request Rejected by Firewall - Session ID: " + supportId);
    dialogController?.showMessageDialog(
      `Die Anfrage wurde von der Firewall abgelehnt. Bitte kopieren und melden Sie die unten aufgeführte Session ID an die EPG: \n\n${supportId}`,
      undefined,
      "Firewall Fehler",
      () => {
        navigator.clipboard.writeText(supportId);
      },
      undefined,
      undefined,
      "Kopieren"
    );
    throw new Error(epcomResponse.data);
  }

  if (response.status === 400 || response.status === 500) {
    const errorMessage =
      getMessageFromErrorType(epcomResponse.data) ??
      returnUndefinedIfOnlyWhitespace(epcomResponse.data.message) ??
      epcomResponse.data.error ??
      epcomResponse.data.message;

    if (errorMessage) dialogController?.showMessageDialog(errorMessage);
    // might be a good idea to throw reject here...but it triggers 2 consecutive error messages
  } else if (
    (epcomResponse.data.errorTyp && response.status === 200) ||
    response.status === 201
  ) {
    let messageToShow = getMessageFromErrorType(epcomResponse.data);
    if (config.onEpcomRemoteError) {
      config.onEpcomRemoteError(
        messageToShow ?? "Beim Laden der Daten ist ein Fehler aufgetreten!"
      );
    } else if (messageToShow)
      dialogController?.showMessageDialog(messageToShow);
  }
  return epcomResponse;
};

const convertResponse = async (
  response: Response,
  config: EpcomRequestConfig
) => {
  const responseContentType = response.headers.get("content-type");
  const data = {
    data: responseContentType?.includes("application/json")
      ? await response.json()
      : config.responseType === "blob" ||
        responseContentType?.includes("application/octet-stream") ||
        responseContentType?.includes("application/vnd.ms-excel")
      ? await response.blob()
      : await response.text(),
    headers: Object.fromEntries(response.headers.entries()),
  };
  return data;
};

const ensureErrorTypesLoaded = (currentRequestUrl: string) => {
  if (
    !currentRequestUrl.includes("errortyp/geterrortypes") &&
    ErrorTypesContainer.length === 0
  ) {
    loadErrorTypeContainer();
  }
};

const fetchWithKeycloak = async (...args: any[]): Promise<EpcomResponse> => {
  let [resource, config] = args;
  if (!KeyCloakService.isLoggedIn()) {
    KeyCloakService.doLogin();
    return {} as EpcomResponse;
  }

  if (maintenanceController?.isUnderMaintenance(resource))
    return Promise.reject("Under maintenance reject");

  ensureErrorTypesLoaded(resource);

  const abortController = new AbortController();
  const enhancedConfigs = authenticatedFetch(abortController, config);
  // request interceptor here

  let url = resource.replace(/\s+/g, "");
  if (config?.params) {
    url = url + "?" + new URLSearchParams(trimParamValues(config.params));
  }

  return fetch(url, enhancedConfigs)
    .then((response) => handleBackendResponse(response, enhancedConfigs))
    .catch((error) => {
      handleFetchError(error, config);
      return Promise.reject(error);
    });
};

const HttpService = {
  HttpMethods,
  registerDialogController: (controller: ModalController) => {
    dialogController = controller;
  },
  registerMaintenanceController: (controller: MaintenanceController) => {
    maintenanceController = controller;
  },
  api: {
    sendGet: (urlToCall: string, config?: EpcomRequestConfig) => {
      return fetchWithKeycloak(urlToCall, config);
    },
    sendEmbededGet: (urlToCall: string, config?: EpcomRequestConfig) => {
      return fetchWithKeycloak(urlToCall, {
        ...config,

        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...trimDataValues(config?.params) }),
      });
    },
    sendDelete: (urlToCall: string, config?: EpcomRequestConfig) => {
      return fetchWithKeycloak(urlToCall, {
        ...config,
        method: "DELETE",
      });
    },
    sendJsonDataPost: (
      urlToCall: string,
      dataToSend: Object,
      config?: EpcomRequestConfig,
      remoteErrorHandler?: Function
    ) => {
      return fetchWithKeycloak(urlToCall, {
        ...config,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...trimDataValues(dataToSend) }),
        onEpcomRemoteError: remoteErrorHandler,
      });
    },
  },
};

export default HttpService;
